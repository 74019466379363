<template>
  <div class="order-pdf container">
    <div class="text-center" v-if="!pdfLoaded">
      <div class="loading-icon"></div>
    </div>
    <div class="text-center" v-if="pdfLoaded && !division.length">
      <p>{{$t('selection.tipEmpty')}}</p>
    </div>
    <template v-if="pdfLoaded && division.length">
      <div class="row" v-for="val in division" :key="val.pbid">
        <div class="col-xs-12 col-sm-6">
          <div class="pdf-card-wrap">
            <div class="card-body">
              <table>
                <tbody>
                  <tr>
                    <td>{{$t('orders.pdfType')}}</td>
                    <td>{{$t('orders.pdfCover')}}</td>
                  </tr>
                  <tr>
                    <td>{{$t('orders.volumeName')}}</td>
                    <td>{{val.title}}</td>
                  </tr>
                  <tr>
                    <td>{{$t('orders.labelCreatedAt')}}</td>
                    <td>{{createdAt}}</td>
                  </tr>
                </tbody>
              </table>
              <div class="btn default btn-block" @click="download(val.pbid, 'cover')">{{$t('orders.downloadPdf')}}</div>
            </div>
          </div>
        </div>
        <div class="col-xs-12 col-sm-6">
          <div class="pdf-card-wrap">
            <div class="card-body">
              <table>
                <tbody>
                  <tr>
                    <td>{{$t('orders.pdfType')}}</td>
                    <td>{{$t('orders.pdfInner')}}</td>
                  </tr>
                  <tr>
                    <td>{{$t('orders.volumeName')}}</td>
                    <td>{{val.title}}</td>
                  </tr>
                  <tr>
                    <td>{{$t('orders.labelCreatedAt')}}</td>
                    <td>{{createdAt}}</td>
                  </tr>
                </tbody>
              </table>
              <div class="btn default btn-block" @click="download(val.pbid, 'inner')">{{$t('orders.downloadPdf')}}</div>
            </div>
          </div>
        </div>
      </div>
    </template>

  </div>
</template>

<script>
import api from 'src/api/'
import { orderBy } from 'lodash'

export default {
  name: 'order-pdf',
  data () {
    return {
      pdfLoaded: false,
      order: {},
      division: []
    }
  },
  created () {
    const { oid } = this.$route.query
    if (oid) {
      this.fetchOrder(oid)
    }
  },
  computed: {
    createdAt () {
      return this.$moment(this.division.createTime).format('YYYY/MM/DD')
    }
  },
  methods: {
    fetchOrder (oid) {
      return api.fetchOrderPrintbook(oid).then(data => {
        this.pdfLoaded = true
        if (!data || data.errors) {
          this.$toast({
            message: this.$t('orders.tipLoadFailed'),
            position: 'bottom'
          })
          return
        }
        this.order = data
        this.division = data.division
        this.division = orderBy(this.division, ['fromMonth'])
      })
    },
    download (pbid, type = 'cover') {
      window.open(`https://ipastbook.com/orders/${this.order.orderId}/pdfs/${pbid}/${type}`)
    }
  }
}
</script>

<style lang="scss" scoped>
.order-pdf {
  max-width: 640px;
  margin-top: 2rem;
  .row {
    display: flex;
    flex-wrap: wrap;
  }
  .pdf-card-wrap {
    display: flex;
    border: 1px solid #d3dce6;
    border-radius: 4px;
    background-color: #fff;
    overflow: hidden;
    font-size: 14px;
    margin-bottom: 1.25rem;
    .card-body {
      flex: 1 1 auto;
      min-height: 1px;
      padding: 1.25rem;
      table {
        width: 100%;
        margin-bottom: 1rem;
      }
      td {
        padding: .2rem;
      }
    }
  }
}
</style>
